<template>
  <v-app id="app">
    <LoaderOverlay />

    <ErrorOverlay />
    <div
      v-if="scrollVisible && sharedDataStore.isDesktop()"
      style="
        background-color: #E5EDF4;
        height: 40px;
        position: fixed;
        width: 100%;
        z-index: 100;
      "
      class="pa-2"
    >
      <!-- <span
        style="font-weight: normal; font-size: 16px; height: 30px"
        class="mt-0 pa-0 greytext"
        >&nbsp;&nbsp;<b>Das Original seit 1995</b> | von Zahnärzten empfohlen
        Version 11.0
      </span> -->

      <!-- Entfernt Padding von Container -->
      <v-row style="font-weight: normal; font-size: 16px; height: 30px">
        <!-- Flex-Container ohne Gutter, zentriert in Höhe -->
        <v-col cols="10" class="d-flex justify-start">
          <!-- Flex-Box für linken Text -->
          <b>Das Original seit 1995</b>&nbsp; | von Zahnärzten empfohlen
        </v-col>
        <v-col cols="2" class="d-flex justify-end pr-4">
          <!-- Flex-Box für rechten Text -->
          Version 12.0
        </v-col>
      </v-row>
    </div>

    <v-app-bar
      v-if="showAppBar"
      app
      v-scroll="makeSmall"
      elevation="1"
      :style="styleSwitch"
      clipped-left
    >
      <!-- :color="showColor" -->
      <!-- <v-app-bar-nav-icon @click="updateDrawerX()"></v-app-bar-nav-icon> -->
      <v-app-bar-nav-icon
        v-if="!sharedDataStore.isDesktop()"
        @click="showMenue = !showMenue"
        style="font-size: 14px"
      ></v-app-bar-nav-icon> 
      <span v-if="!sharedDataStore.isDesktop()">
        <router-link class="logoSmall" to="/">WaizmannTabelle</router-link>
      </span>
      <span v-if="sharedDataStore.isDesktop()" class="title ml-4 mr-5">
        <router-link to="/">
          <a class="logo"> WaizmannTabelle </a> 
        </router-link>
        <span class="logo" v-if="sharedDataStore.isDesktop()">
        
          <span v-if="berechnungStore.berechnung">
            <router-link
              style="font-weight: normal !important"
              :class="
                sharedDataStore.showFontColor(
                  berechnungStore.berechnung.ber_segment
                )
              "
              :to="`/vergleich/ergebnis/${berechnungStore.berechnung.ber_identity_token}`"
            >
              {{
                sharedDataStore.showSup(berechnungStore.berechnung)
              }}</router-link
            >
          </span>
          <span
            class="text-blue"
            v-if="!berechnungStore.berechnung"
            style="font-weight: normal !important"
            >2025
          </span>
        </span>
      </span>

      <span v-if="sharedDataStore.isDesktop()">
        <v-btn
           style="font-size: 20px"
          rounded="0"
          class="button_caps blackText"
          @click="berechnungStore.setShowPopApp('alleberechnungen')"
          v-if="berechnungStore.berechnung"
          ><b>Infopaket anfordern</b>
        </v-btn>

        <v-btn
          v-if="!berechnungStore.berechnung"
          style="font-size: 20px"
          rounded="0"
          class="button_caps ml-2"
          ><router-link  class="blackText" to="/zusatzversicherung">
            <b>Rechner starten</b>
          </router-link>
        </v-btn>

        <v-btn
          v-if="berechnungStore.berechnung"
          style="font-size: 20px"
          rounded="0"
          class="button_caps ml-2"
          ><router-link  class="blackText" to="/zusatzversicherung">
            <b>Rechner</b>
          </router-link>
        </v-btn>

        <v-btn
          style="font-size: 20px;"
          rounded="0"
          class="button_caps ml-2"
        >
          <router-link to="/beratung" class="blackText">Beratung</router-link>
        </v-btn>

        <v-btn
          style="font-size: 20px"
          rounded="0"
          class="button_caps ml-2"
        >
          <a href="https://www.waizmannpro.de"  class="blackText" target="_blank"
            >für Vermittler</a
          >
        </v-btn>

        <v-btn
          style="font-size: 20px"
          rounded="0"
          class="button_caps ml-2"
        >
          <a href="https://www.teamwaizmann.de"  class="blackText">für Zahnärzte</a>
        </v-btn>
      </span>

      <span v-if="!sharedDataStore.isMobile()">
        <img
          @click="berechnungStore.setShowPopApp('clearmentschutz')"
          width="128"
          class="ml-4 mt-2"
          :src="require('@/assets/clearment/smallText.png')"
        />
      </span>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-navigation-drawer
      v-if="showMenue"
      v-model="showMenue"
     
      class="pa-4"
      mode="floating"
    >

    <MenueRechner
              class="ml-2"
              v-if="berechnungStore.berechnung"
              :berechnung="berechnungStore.berechnung"
            />
            
            <!-- <MenueNeu
              class="ml-2"
              v-if="!berechnungStore.berechnung"
              :berechnung="berechnungStore.berechnung"
            /> -->
    </v-navigation-drawer>

    <v-main
      class="d-flex align-center justify-center"
      style="max-width: 1400px"
    >
      <v-container
        fluid
        class="white pa-0"
        :style="{ marginTop: containerHeight + 'px' }"
      >
        <v-alert
          v-if="showNotAvaiable"
          type="error"
          style="height: 200px; padding-left: 100px"
        >
          <h1 class="white--text">
            Wir haben derzeit ein technisches Problem - bitte besuchen Sie uns
            in 3-4 Stunden noch einmal
          </h1>
          <p class="white--text">
            Alle 1-2 Jahre haben wir ein technisches Problem, dass die Funktion
            der WaizmannTabelle einschränkt. Das sind die stressigsten Stunden
            des Jahres. Jetzt ist es einmal wieder so weit. In den letzten 25
            Jahren haben wir es es immer geschafft in wenigen Stunden wieder
            online zu sein. Drücken Sie uns die Daumen und besuchen Sie uns
            etwas später wieder ;-)
          </p>
        </v-alert>

        <v-row class="ml-4" >
          <v-col
            cols="12"
            md="2"
            class="mt-6"
            v-if="sharedDataStore.isDesktop() && stopMenue"
            >&nbsp;</v-col
          >
          <v-col
            cols="12"
            md="2"
            class="mt-6"
            v-if="(showMenue || sharedDataStore.isDesktop()) && !stopMenue"
          >
          <MenueNeu
             v-if="!berechnungStore.berechnung"
              class="mt-12 pr-4"
              style="
                position: fixed;
                top: 100px;
                width: 180px;
                border-right: 2px solid #f2f2f2;
                min-height: 600px;
              " />
         
            <MenueRechner
              class="mt-12"
              style="position: fixed; top: 100px; width: 180px"
              v-if="berechnungStore.berechnung && sharedDataStore.isDesktop()"
              :berechnung="berechnungStore.berechnung"
            />
     
          </v-col>
          <v-col cols="12" md="10">
            <router-view style="background-color: #fff; margin-top: 60px" />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <div v-if="berechnungStore.showFooter" class="fixedFooter">
      <router-link to="/impressum">Impressum</router-link>
      | <router-link to="/datenschutz">Datenschutzerklärung</router-link>
    </div>
    <v-dialog v-model="berechnungStore.showPopApp" width="888">
      <v-card class="pa-4">
        <v-row
          ><v-col cols="11"></v-col
          ><v-col cols="1"
            ><v-btn icon @click="berechnungStore.closePopApp">
              <v-icon>mdi-close</v-icon>
            </v-btn></v-col
          ></v-row
        >
        <AlleBerechnungen
          v-if="berechnungStore.showPopAppType == 'alleberechnungen'"
        />
        <ClearmentSchutz
          v-if="berechnungStore.showPopAppType == 'clearmentschutz'"
        />
        <TarifOptions
          :tarif="berechnungStore.tarif"
          v-if="berechnungStore.showPopAppType == 'showTarifOptions'"
        />
      </v-card>
    </v-dialog>
    <v-dialog v-model="berechnungStore.showPopChat" width="888">
      <ChatWindow v-if="berechnungStore.showPopChat" />
    </v-dialog>
  </v-app>
</template>

<script setup>
  import { ref, onMounted, nextTick, watch } from "vue";
  import { useBerechnungStore } from "@/stores/berechnung";
  import { useGuestStore } from "@/stores/guest";
  import { useSharedDataStore } from "@/stores/sharedData";
  import LoaderOverlay from "@/components/base/LoaderOverlay.vue";
  import ErrorOverlay from "@/components/base/ErrorOverlay.vue";
  import AlleBerechnungen from "@/components/berechnung/vergleich/AlleBerechnungen";
  import MenueRechner from "@/components/berechnung/MenueRechner";
  import MenueNeu from "@/components/berechnung/MenueNeu";
  import ClearmentSchutz from "@/components/tarif/info/ClearmentSchutz";
  import TarifOptions from "@/components/tarif/TarifOptions";
  import ChatWindow from "@/components/chat/ChatWindow";
  import { useRoute } from "vue-router";

  //import { useLoaderStore } from "@/stores/loaderStore";
  //import { useRoute } from "vue-router";
  import BaseCalls from "@/services/BaseCalls";
  //const loaderStore = useLoaderStore();
  // const route = useRoute();
  const route = useRoute();
  const sharedDataStore = useSharedDataStore();
  const berechnungStore = useBerechnungStore();
  const guestStore = useGuestStore();

  // import MenueDefault from "@/components/site/MenueDefault.vue";

  // import LoaderOverlay from "@/components/base/LoaderOverlay.vue";
  // import SuccessOverlay from "@/components/base/SuccessOverlay.vue";

  const lastBerechnung = ref(false);
  const showAppBar = ref(true);
  const showMenue = ref(false);
  const stopMenue = ref(false);
  const showNotAvaiable = ref(false);
  const scrollVisible = ref(true);
  const styleSwitch = ref("margin-top:40px;"); // Verwenden Sie `const` für konsistente Reaktivität
  const containerHeight = ref("20");

  //const bearbeiter = computed(() => bearbeiterStore.bearbeiterData);
  // function setLoading(loading) {
  //   loaderStore.setLoader(loading);
  // }

  const contentHeight = ref(0);

  // Funktion, um die Höhe des Inhalts zu messen
  function measureContentHeight() {
    const contentElement = document.getElementById("app");
    if (contentElement) {
      return contentElement.offsetHeight;
    }
    return 0;
  }

  function sendHeightToParent(newHeight) {
    // window.parent.postMessage(
    //   {
    //     frameHeight: newHeight,
    //   },
    //   "*"
    // );
    console.log("height: " + newHeight);
  }
  async function checkChat() {
    let berSelect = {};
    berSelect.action = "isChatOpen";
    try {
      const resp = await BaseCalls.postChat(berSelect); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.chatOpen) {
        berechnungStore.setShowPopChatButton();
      } else {
        berechnungStore.closePopChatButton();
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }
  async function getShowBerechnungen() {
    let berSelect = {};
    berSelect.action = "getShowBerechnungen";
   
    try {
      const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.berechnungen) {
        berechnungStore.setShowBerechnungen(resp.data.berechnungen);
        console.log("showBerechnungen ");
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }
  async function getLastBerechnung(guest_id) {
    let berSelect = {};
    berSelect.action = "getLastBerechnung";
    berSelect.ber_guest_id = guest_id;
    console.log("get berechnung " + berSelect.ber_guest_id);
    try {
      const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.berechnung) {
        lastBerechnung.value = resp.data.berechnung;
        berechnungStore.setBerechnung(resp.data.berechnung);
        console.log("last berechnung ");
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }
  async function checkLinkReferrer() {
    var checkStringGoogle = document.referrer,
      substring1 = ".google";
    var isGoogleLink = checkStringGoogle.indexOf(substring1) !== -1;

    var checkStringWaizmann = document.referrer,
      substring2 = ".waizmann";
    var isWaizmannLink = checkStringWaizmann.indexOf(substring2) !== -1;

    var checkStringLocal = document.referrer,
      substring3 = "local";
    var isLocalLink = checkStringLocal.indexOf(substring3) !== -1;

    var checkStringTeleping = document.referrer,
      substring4 = "teleping.de";
    var isTelepingLink = checkStringTeleping.indexOf(substring4) !== -1;

    if (isGoogleLink) {
      let guestSelect = {};
      guestSelect.action = "insertGoogleVisit";
      guestSelect.gg_site = window.location.href;
      try {
        await BaseCalls.postGuest(guestSelect);
      } catch (err) {
        console.log("error inserting Google visit");
      }
    }

    if (!isGoogleLink && !isWaizmannLink && !isLocalLink && !isTelepingLink) {
      let guestSelect = {};
      guestSelect.action = "checkLinkReferrer";
      guestSelect.ref = document.referrer;
      try {
        await BaseCalls.postGuest(guestSelect); // Korrigiert, um .value zu verwenden
      } catch (err) {
        console.log("error ref");
      }
    }
  }
  async function checkInsertGuest() {
    let guestSelect = {};
    guestSelect.action = "checkInsertGuest";
    try {
      const resp = await BaseCalls.postGuest(guestSelect); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.guest) {
        guestStore.setGuest(resp.data.guest);
        console.log("last guest");
        getLastBerechnung(resp.data.guest.guest_id);
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }

  function makeSmall() {
    const currentScrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;
    if (currentScrollPosition > 100) {
      styleSwitch.value = "margin-top:0px;";
      scrollVisible.value = false;
    } else {
      if (sharedDataStore.isDesktop()) {
        styleSwitch.value = "margin-top:40px;";
        scrollVisible.value = true;
      }
    }
  }
  function checkMenue() {
    const pattern =
      /^\/(angebot(\/(?!(id\/?\d*))[^\s]*)?|vergleich\/starten(\/.*)?)$/;
    if (pattern.test(route.path)) {
      console.log("closeMenu");
      stopMenue.value = true;
    } else {
      stopMenue.value = false;
    }
  }



  watch(
    () => [route.path, route.params, route.query],
    () => {
      // Die checkChat Funktion wird aufgerufen, sobald sich der Pfad ändert
      checkChat();
      checkMenue();
      window.scrollTo(0, 0);
    },
    {}
  );

  onMounted(() => {
    //setLoading(true);
    if (sharedDataStore.isDesktop()) {
      styleSwitch.value = "margin-top:40px;";
      containerHeight.value = 40;
    } else {
      styleSwitch.value = "margin-top:0px;";
      containerHeight.value = 0;
    }
    checkChat();
    checkInsertGuest();
    checkLinkReferrer();
    getShowBerechnungen();
    nextTick(() => {
      contentHeight.value = measureContentHeight(); // Erste Messung nach dem Mount

      watch(contentHeight, (newHeight, oldHeight) => {
        if (Math.abs(newHeight - oldHeight) > 50) {
          sendHeightToParent(newHeight);
        }
      });

      // Optional: Höhe regelmäßig überprüfen
      setInterval(() => {
        const newHeight = measureContentHeight();
        if (newHeight !== contentHeight.value) {
          contentHeight.value = newHeight;
        }
      }, 1000); // Überprüfen jede Sekunde
    });
  });

  window.addEventListener("scroll", makeSmall); // Fügen Sie den Event Listener hinzu, um `makeSmall` bei Scroll-Events auszuführen
</script>

<style>
  .fixedFooter {
    position: fixed; /* Fixiert den Div innerhalb des Ansichtsfensters */
    bottom: 0; /* Positioniert den Div am unteren Rand des Ansichtsfensters */
    left: 0; /* Stellt sicher, dass der Div sich über die gesamte Breite erstreckt */
    width: 100%; /* Stellt die Breite auf 100% des Ansichtsfensters ein */
    background-color: #f2f2f2; /* Dunkler Hintergrund für bessere Sichtbarkeit */
    color: grey; /* Weiße Textfarbe */
    text-align: left; /* Zentriert den Text innerhalb des Divs */
    padding: 8px 8px 8px 8px; /* Fügt oben und unten etwas Polsterung hinzu */
    z-index: 1000; /* Stellt sicher, dass der Footer über anderen Elementen schwebt */
  }
  html {
    font-size: 15px !important;
  }
  .caption {
    font-size: 13px !important;
  }

  ul {
    margin-left: 20px;
    margin-top: 4px;
    margin-bottom: 8px;
  }
  ol {
    margin-left: 20px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  a {
    color: #2196f3 !important;
    text-decoration: none !important;
  }
  .redtext {
    color: #e54c39 !important;
  }
  .greentext {
    color: #4caf50 !important;
  }
  .greenground {
    background-color: #4caf50 !important;
  }
  .blackground {
    background-color: #555555 !important;
  }

  .wtb-chat-app {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 350px;

    /* height: 400px; */
    border: 1px solid grey;
    z-index: 1000;
    background: #ccc;
  }
  .logo {
    font-size: 36px;
    text-decoration: none;
    font-weight: bold;
  }
  .logoSmall {
    font-size: 24px;
    text-decoration: none;
    font-weight: bold;
  }
  .sonder {
    font-size: 36px;
    text-decoration: none;
  }
  .logosmall {
    font-size: 24px;
    text-decoration: none;
    font-weight: bold;
  }
  .header_btn_mobil {
    text-transform: none !important;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
  }
  .header_btn {
    text-transform: none !important;
    font-size: 16px;
  }
  .nocaps {
    text-transform: none !important;
  }
  .v-navigation-drawer__border {
    display: none;
  }
  a {
    color: #0000ee; /* Blaue Farbe */
    text-decoration: underline; /* Unterstreichen des Textes */
    cursor: pointer; /* Ändert den Cursor in einen Zeiger */
  }
  #app {
    color: #555555 !important;
  }
  .layout {
    color: #555555 !important;
  }
  .blue {
    color: #305fa5;
  }
  .blackText {
    color: #555555 !important;
  }
  .titleh1 {
    font-size: 24px;
    color: #555555 !important;
    margin-bottom: 4px;
  }
  .divider {
    border-bottom: 1px solid #2196f3 !important;
    margin-top: 12px;
    margin-bottom: 24px;
  }
  .titleh2 {
    font-size: 16px;
    color: #555555 !important;
  }
  .baseText {
    font-size: 16px;
  }
  .greyText {
    color: #0000008a !important;
  }
  .textWhite {
    color: #ffffff !important;
  }
  .greyTextSize {
    font-size: 13px !important;
    color: #0000008a !important;
  }
  .blueTitle {
    font-size: 16px;
    color: #2196f3 !important;
  }
  .titleHome {
    font-size: 32px;
  }
  .headline {
    font-size: 24px !important;
  }

  .blueground {
    background-color: #2196f3;
  }
  .pinkground {
    background-color: #e91e63;
  }

  .greenground {
    background-color: #4caf50;
  }
  .yellowground {
    background-color: #fecf0c;
  }
  .orangeground {
    background-color: #ff8000;
  }
  .turkground {
    background-color: #088a85;
  }
  .cianground {
    background-color: #00bcd4;
  }
  .yellowcolor {
    color: #fecf0c !important;
  }
  .purpleground {
    background-color: #513b83;
  }
  .purplecolor {
    color: #513b83 !important;
  }
  .bluecolor {
    color: #2196f3!;
  }
  .greyground {
    background-color: #f2f2f2;
  }

  .noUnderline {
    text-decoration: none !important;
  }
  .button_caps {
    text-transform: none !important;
  }
  .wordwrap {
    word-break: normal;
  }
  a:link {
    text-decoration: none !important;
  }
  h1 {
    color: #555555 !important;
  }
  h2 {
    color: #555555 !important;
  }
  h3 {
    color: #555555 !important;
  }
</style>

<template>
  <div style="height: 1000px">
    <v-row>
      <v-col cols="12" md="9">

        <BeschreibungHeaderZahn
          v-if="tarif.tarif_type == 0"
          :tarif="tarif"
          :preise="preise"
        />
        <BeschreibungHeaderBrille
          v-if="tarif.tarif_type == 1"
          :tarif="tarif"
          :preise="preise"
        />
        <BeschreibungHeaderHeil
          v-if="tarif.tarif_type == 2"
          :tarif="tarif"
          :preise="preise"
        />
        <BeschreibungHeaderKrank
          v-if="tarif.tarif_type == 3"
          :tarif="tarif"
          :preise="preise"
        />
        <!-- <RechnerStarten :tarif="tarif" /> -->
        <AnnahmeCheckStart v-if="tarif" :tarif="tarif" />
        <v-divider :thickness="2" class="my-4"></v-divider>
        <SeoHeaderZahn v-if="tarif.tarif_type == 0" :tarif="tarif" />
        <LeistungenIndex
          v-if="tarif.tarif_type == 0"
          :tarif="tarif"
          :bereiche="bereiche"
          :budgets="budgets"
          :budgetbeginn="budgetbeginn"
        />
        <EckDatenBrille v-if="tarif.tarif_type == 1" :tarif="tarif" />
        <EckDatenHeil v-if="tarif.tarif_type == 2" :tarif="tarif" />
        <EckDatenKrank v-if="tarif.tarif_type == 3" :tarif="tarif" />
        <v-divider
          :thickness="2"
          v-if="fragen && fragen.length > 0"
          class="my-4"
        ></v-divider>
        <AntragFragen
          v-if="fragen && fragen.length > 0"
          :tarif="tarif"
          :fragen="fragen"
        />
        <v-divider
          :thickness="2"
          v-if="test && test.length > 0"
          class="my-4"
        ></v-divider>
        <TestBerichte
          v-if="test && test.length > 0"
          :tarif="tarif"
          :test="test"
        />
        <v-divider
          :thickness="2"
          v-if="tarif.tarif_type == 0"
          class="my-12"
        ></v-divider>
        <!-- <ForumFragen v-if="tarif.tarif_type == 0" :tarif="tarif" /> -->

        <WaizmannWert
          id="waizmannwert"
          v-if="tarif.tarif_type == 0 && waizmannwert"
          :wwprop="waizmannwert"
          :tarif="tarif"
        />
        <v-divider
          :thickness="2"
          v-if="tarif.tarif_type == 0 && forum && forum.length > 0"
          class="my-4"
        ></v-divider>

        <ForumFragen
          v-if="tarif.tarif_type == 0 && forum && forum.length > 0"
          :tarif="tarif"
          :forum="forum"
        />
      </v-col>

      <v-col cols="12" md="3" v-if="sharedDataStore.isDesktop()">
        <span v-if="avb.length > 0">
          <h3>Downloads & AVB`s</h3>
          Wichtige Downloads der {{ tarif.un_name }}
          {{ tarif.tarif_title }} Zahnzusatzversicherung.
          <ul class="mt-2">
            <li v-for="a in avb" v-bind:key="a.vdocs_id">
              <a :href="'/download/' + a.vdoc_dateiname" target="_blank">{{
                a.vdoc_text
              }}</a>
            </li>
          </ul>
        </span>
        <v-divider :thickness="2" class="my-4"></v-divider>
        <h3>Beitragstabelle:</h3>

        <span v-if="tarif.tarif_altersrueckstellung == 1">
          Die Beiträge sind <b>ohne</b> Altersrückstellungen kalkuliert. Die
          Beiträge werden regelmäßig entsprechend Ihres Alters angepasst.
        </span>
        <span v-if="tarif.tarif_altersrueckstellung == 0">
          Die Beiträge sind <b>mit</b> Altersrückstellungen kalkuliert. Dadurch
          bleiben die Beiträge nach Abschluss stabil. </span
        ><br /><br />
        <b>Monatlicher Beitrag bei Eintrittsalter:</b><br />
        <span v-for="p in preise" :key="p.preis_alter" class="ma-2">
          {{ p.preis_alter }} Jahre: {{ p.preis_value }} € <br
        /></span>
        <router-link :to="sharedDataStore.getStartLink(tarif.tarif_type)"
          ><b>jetzt <b>exakten</b> Beitrag berechnen</b></router-link
        >
        <span v-if="tarif.tarif_altersrueckstellung == 1 && preisSpruenge">
          <br /><br />
          <b>Ab diesem Alter wird der Beitrag angepasst:</b><br />
          <span v-for="p in preisSpruenge" :key="p.preis_alter" class="ma-2">
            {{ p.preis_alter }} Jahre: {{ p.preis_value }} € <br
          /></span>
        </span>
        <router-link :to="sharedDataStore.getStartLink(tarif.tarif_type)"
          ><b>jetzt <b>exakten</b> Beitrag berechnen</b></router-link
        >
        <v-divider :thickness="2" class="my-4"></v-divider>
        <h3>Rechnung einreichen</h3>
        <span v-html="nl2br(tarif.un_rechnung)"></span>
        <v-divider :thickness="2" class="my-4"></v-divider>
        <h3>{{ tarif.un_name }} kündigen</h3>
        Hier finden Sie Informationen zur
        <router-link :to="`/sofort_kuendigen/${tarif.un_url}`"
          >Kündigung der {{ tarif.un_name }} {{ tarif.tarif_title }}
          {{ sharedDataStore.getSegmentName(tarif.tarif_type) }}</router-link
        >.

        <v-divider :thickness="2" class="my-4"></v-divider>
        <span v-if="weitere">
          <h3>Weitere Tarife {{ tarif.un_name }}</h3>
          <ul>
            <li v-for="w in weitere" :key="w.tarif_id">
              <router-link
                :to="
                  sharedDataStore.getBeschreibungLink(w.tarif_type, w.tarif_id)
                "
                >{{ tarif.un_name }} {{ w.tarif_title }}</router-link
              >
            </li>
          </ul>
        </span>
        <span v-if="moreThreads.length > 0">
          <v-divider :thickness="2" class="my-4"></v-divider>
          <h3>Forum {{ tarif.tarif_title }}</h3>
          <ul>
            <li v-for="frage in moreThreads" :key="frage.comment_id">
              <a
                :href="`/zahnzusatzversicherung_forum/${tarif.tarif_forum_name}/${frage.comment_speaking_link}`"
                >{{ frage.comment_title }}</a
              >
            </li>
          </ul>
        </span>
      </v-col>
    </v-row>
    <div v-if="appLoaded" id="appLoaded"></div>
  </div>
</template>

<script setup>
  import { ref, watch, getCurrentInstance, onMounted } from "vue";
  import BeschreibungHeaderZahn from "@/views/site/tarif/zahn/BeschreibungHeader";
  import BeschreibungHeaderBrille from "@/views/site/tarif/brille/BeschreibungHeader";
  import BeschreibungHeaderHeil from "@/views/site/tarif/heil/BeschreibungHeader";
  import BeschreibungHeaderKrank from "@/views/site/tarif/krank/BeschreibungHeader";
  import SeoHeaderZahn from "@/views/site/tarif/zahn/SeoHeader";

  import LeistungenIndex from "@/views/site/tarif/zahn/LeistungenIndex";
  import EckDatenBrille from "@/components/tarif/mini/brille/EckDaten";
  import EckDatenHeil from "@/components/tarif/mini/heil/EckDaten";
  import EckDatenKrank from "@/components/tarif/mini/krank/EckDaten";
  //import RechnerStarten from "@/views/site/tarif/RechnerStarten";
  import AnnahmeCheckStart from "@/views/site/tarif/AnnahmeCheckStart";
  import AntragFragen from "@/views/site/tarif/AntragFragen";
  import TestBerichte from "@/views/site/tarif/TestBerichte";
  import ForumFragen from "@/views/site/tarif/ForumFragen";
  import WaizmannWert from "@/components/tarif/zahn/WaizmannWert";
  import { useSharedDataStore } from "@/stores/sharedData";
  import BaseCalls from "@/services/BaseCalls";
  import { useNl2Br } from "@/composable/useNl2Br";
  const { nl2br } = useNl2Br();
  //   import { useBerechnungStore } from "@/stores/berechnung";
  import { useLoaderStore } from "@/stores/loaderStore";
  import { useRoute } from "vue-router";
  const loaderStore = useLoaderStore();
  const route = useRoute();
  //   const berechnungStore = useBerechnungStore();
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const forum = ref(false);
  const tarif = ref(false);
  const avb = ref(false);
  const test = ref(false);
  const budgetbeginn = ref(false);
  const budgets = ref(false);
  const bereiche = ref(false);
  const preise = ref(false);
  const preisSpruenge = ref(false);
  const weitere = ref(false);
  const fragen = ref(false);
  const waizmannwert = ref(false);
  const moreThreads = ref(false);
  const appLoaded = ref(false);

  function setLoading(loading) {
    loaderStore.setLoader(loading);
  }
  async function getTarif() {
    console.log("getTarif");
    let select = {};
    select.action = "getTarifComplete";
    select.tarif_id = route.params.id;
    setLoading(true);
    try {
      const resp = await BaseCalls.postTarif(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.tarif) {
        console.log("getTarifSuccess");
        tarif.value = resp.data.tarif;
        test.value = resp.data.test;
        avb.value = resp.data.avb;
        forum.value = resp.data.forum;
        preise.value = resp.data.preise;
        budgetbeginn.value = resp.data.budgetbeginn;
        budgets.value = resp.data.budgets;
        bereiche.value = resp.data.bereiche;
        preisSpruenge.value = resp.data.preisSpruenge;
        fragen.value = resp.data.fragen;
        weitere.value = resp.data.weitere;
        waizmannwert.value = resp.data.waizmannwert;
        moreThreads.value = resp.data.moreThreads;

        setLoading(false);
        appLoaded.value = true;
        proxy.$updateDocumentHead(tarif.value.titleTag, tarif.value.descTag);
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }

  watch(
    () => route.params,
    () => {
      // Die Funktion checkRouteChange wird aufgerufen, wenn sich die Route-Parameter ändern
      getTarif();
    },
    { deep: true }
  );
  onMounted(() => {
    getTarif();
  });
</script>
